import {lazy} from 'react';
import { PostHogProvider} from 'posthog-js/react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Router, Redirect } from "@reach/router";
import './css/App.scss';
import './css/styles.scss';
// import Home from './views/Home'
const Home = lazy(() => import('./views/Home'));
const Topnav = lazy(() => import('./components/Topnav'));
const Footer = lazy(() => import('./components/Footer'));
const StudentLoans  = lazy(() => import( './views/StudentLoans'));
const StudentLoansLanding  = lazy(() => import( './views/Landings/StudentLoansLanding'));
const StudentLoansLandingAustralia  = lazy(() => import( './views/StudentLoansLandingAustralia'));
const StudentLoansLandingCanada  = lazy(() => import( './views/StudentLoansLandingCanada'));
const StudentLoansLandingUK  = lazy(() => import( './views/StudentLoansLandingUK'));
const StudentLoansLandingUS  = lazy(() => import( './views/StudentLoansLandingUS'));
const StudentLoansLandingMobile  = lazy(() => import( './views/StudentLoansLandingMobile'));
const StudentLoansLandingMatch  = lazy(() => import( './views/StudentLoansLandingMatch'));
const StudentLoansLandingProdigy3  = lazy(() => import( './views/StudentLoansLandingProdigy3'));
const StudentLoansLandingAvanse  = lazy(() => import( './views/StudentLoansLandingAvanse'));
const StudentLoansLandingHDFC  = lazy(() => import( './views/StudentLoansLandingHDFC'));
const StudentLoansLandingVisaMadeEasy  = lazy(() => import( './views/Landings/StudentLoansLandingVisaMadeEasy'));
const Terms  = lazy(() => import( './views/Terms'));
const Privacy  = lazy(() => import( './views/Privacy'));
const Faqs  = lazy(() => import( './views/Faqs'));
const HowItWorks  = lazy(() => import( './views/HowItWorks'));
const Contact  = lazy(() => import( './views/Contact'));
const TestimonialsView  = lazy(() => import( './views/TestimonialsView'));
const Partners  = lazy(() => import( './views/Partners'));
const SignIn  = lazy(() => import( './views/Portal/SignIn'));
const SignUp  = lazy(() => import( './views/Portal/SignUp'));
const CalendlyWidget  = lazy(() => import( './views/Calendly'));
const SiteMap  = lazy(() => import( './views/Site-Map'));
const Dashboard  = lazy(() => import( './views/Portal/Dashboard'));
const StudentLoansLandingAndromeda  = lazy(() => import( "./views/StudentLoansLandingAndromeda"));
const AdmissionLanding2023  = lazy(() => import( './views/AdmissionLanding2023'));
const ForgetPassword  = lazy(() => import( './views/Portal/ForgetPassword'));
const MyDocuments  = lazy(() => import( './views/Portal/MyDocuments'));
const Options  = lazy(() => import( './views/Portal/Offers'));
const Edit  = lazy(() => import( './views/Portal/Edit'));
const NewApplication  = lazy(() => import( './views/Portal/New'));
const AdmissionLandingProfileEvaluation  = lazy(() => import( './views/AdmissionLandingProfileEvaluation'));
const AdmissionLandingEngineeringAndMasters  = lazy(() => import( './views/AdmissionLandingEngineeringAndMasters'));
const StudentLoansLandingMpower  = lazy(() => import( './views/Landings/StudentLoansLandingMPOWER'));
const StudentLoansLandingWithoutCollateral  = lazy(() => import( './views/Landings/StudentLoansLandingWithoutCollateral'));
const StudentLoansLandingAuxilo  = lazy(() => import( './views/StudentLoansLandingAuxilo'));
const AdmissionLanding = lazy(() => import('./views/AdmissionLanding'));
const IeltsToeflLanding = lazy(() => import('./views/AdmissionsIeltsToeflLanding'));
const AdmissionsLandingFallIntake = lazy(() => import('./views/Landings/AdmissionsLandingFallIntake'));
const AdmissionsLandingPartTimeJobs = lazy(() => import('./views/Landings/AdmissionsLandingPartTimeJobs'));
const AdmissionsLandingGre = lazy(() => import('./views/Landings/AdmissionsLandingGre'));
const AdmissionsLandingGreExamFees = lazy(() => import('./views/Landings/AdmissionsLandingGreExamFees'));
const AdmissionsLandingIELTS = lazy(() => import('./views/Landings/AdmissionsLandingIELTS'));
const AdmissionsLandingStudyAbroadConsultants = lazy(() => import('./views/Landings/AdmissionsLandingStudyAbroadConsultants'));
const AdmissionsLandingAdmitPredictor = lazy(() => import('./views/Landings/AdmissionsLandingAdmitPredictor'));
const StudentLoansLandingForAbroadStudies = lazy(() => import('./views/StudentLoansLandingForAbroadStudies'));
const StudentLoansLandingCollegeFinder = lazy(() => import('./views/Landings/StudentLoansLandingCollegeFinder'));
const StudentLoansLandingNoCosigner = lazy(() => import('./views/Landings/StudentLoansLandingNoCosigner'));
const StudentLoansLandingIncred = lazy(() => import('./views/StudentLoansLandingIncred'));

// const ThemeContext = createContext(null);
//POST HOG options
const options = {
  api_host: 'https://app.posthog.com',
}

function App() {
  // const variant = useFeatureFlagVariantKey('contact-form-label-on-top')
  // const [thisVariant, setThisVariant] = useState(null);
  // console.log('contact-form-label-on-top', variant)
  const landingPages = ['/admissions-landing', '/student-loans-landing', '/student-loans-landing/', '/student-loans-landing-australia', '/student-loans-landing-canada','/student-loans-landing-uk', '/student-loans-landing-us', '/student-loans-landing-canada/','/student-loans-landing-uk/', '/student-loans-landing-us/','/student-loans-landing-australia', '/student-loans-landing-australia/', '/student-loans-landing-linkedin', '/student-loans-landing-linkedin/', '/student-loans-landing-mobile', '/student-loans-landing-mobile/', 'student-loans-landing-match', 'student-loans-landing-match/', '/student-loans-landing-prodigy', '/student-loans-landing-prodigy/', '/student-loans-landing-andromeda', '/student-loans-landing-andromeda/', '/admissions-landing-2023', '/admissions-landing-2023/', '/admissions-landing-profile-evaluation', '/admissions-landing-profile-evaluation/', '/admissions-ielts-toefl-landing', '/admissions-ielts-toefl-landing/', '/admission-landing-engineering-and-masters', 'admission-landing-engineering-and-masters/', '/student-loans-landing-prodigy-3', '/student-loans-landing-prodigy-3/', '/student-loans-landing-mpower', '/student-loans-landing-mpower/', '/student-loans-landing-avanse', '/student-loans-landing-avanse/', '/student-loans-landing-usc-partner-collateral', '/student-loans-landing-usc-partner-collateral/', '/student-loans-landing-compare', '/student-loans-landing-compare/' ];

  const portalPages = ['new-options', 'options', 'new-dashboard', 'dashboard', 'new-my-documents', 'my-documents','new-edit', 'edit', 'new-new'];
  return (
    <>
    <PostHogProvider 
      apiKey='phc_p8SXHiBHZNGfzOznHWcRTVJNK8QrDYXVCgrVVuPf6Lb'
      options={options}
    >
     <HelmetProvider>
      <Helmet>
        <title>Education Loans for International Students USA</title>

      </Helmet>
    {/* <ThemeContext.Provider value={{landingPages:landingPages, portalPages: portalPages}}> */}
      <div className="App">

        <Topnav context={{landingPages:landingPages, portalPages: portalPages}} />
         
        <Router>
          {/* When adding a new route here, be sure to add it to nomadcredit-rebuild/config/routes.rb as well - look at an existing example to see the pattern to follow */}
          {/* <Home path="/index"  /> */}
          <Home path="/"  />
          <Redirect from="/index" to="/" noThrow />
          <Redirect from="/faq" to="/faqs" noThrow />
          <Redirect from="/health-insurance" to="/" noThrow />
          <Redirect from="/admissions-help" to="/admissions" noThrow />
          <Redirect from="/student_loans" to="/student-loans" noThrow />
          <Redirect from="/new-how-it-works" to="/how-it-works" noThrow />
          <Redirect from="/about-us" to="/how-it-works" noThrow />
          <Redirect from="/sign_in" to="/sign-in" noThrow />
          <Redirect from="/sign_up" to="/sign-up" noThrow />

          <AdmissionsLandingStudyAbroadConsultants path="/admissions"  />
          <AdmissionsLandingAdmitPredictor path="/admit-predictor"  />
          <AdmissionLanding path="/admissions-landing" />
          <AdmissionLanding2023 path="/admissions-landing-2023" />
          <AdmissionLandingProfileEvaluation path="/admissions-landing-profile-evaluation" />
          <AdmissionLandingEngineeringAndMasters path="/admission-landing-engineering-and-masters" />
          <AdmissionsLandingGre path="/gre" />
          <AdmissionsLandingGreExamFees path="/gre/gre-exam-fee" />
          <AdmissionsLandingIELTS path="/ielts" />

          <AdmissionsLandingFallIntake path="/fall-intake-in-the-us" />
          <AdmissionsLandingPartTimeJobs path="/us-part-time-jobs" />
          <IeltsToeflLanding path="/admissions-ielts-toefl-landing" />
          <StudentLoans path='/student-loans' />
          <StudentLoansLanding path='/student-loans-landing' />
          <StudentLoansLandingAustralia path='/student-loans-landing-australia' />
          <StudentLoansLandingCanada path="/student-loans-landing-canada" />
          <StudentLoansLandingUK path="/student-loans-landing-uk" />
          <StudentLoansLandingUS path="/student-loans-landing-us" />
          <StudentLoansLanding path="/student-loans-landing-linkedin" />
          <StudentLoansLandingMobile path="/student-loans-landing-mobile" />
          <StudentLoansLandingMatch path="/student-loans-landing-match" />
          <StudentLoansLandingProdigy3 path="/student-loans-landing-prodigy" />
          <StudentLoansLandingProdigy3 path="/student-loans-landing-prodigy-2" />
          <StudentLoansLandingProdigy3 path="/student-loans-landing-prodigy-3" />
          <StudentLoansLandingProdigy3 path="/student-loans-landing-prodigy-finance" />
          <StudentLoansLandingAvanse path="/student-loans-landing-avanse" /> 
           <StudentLoansLandingAndromeda path="/student-loans-landing-andromeda" />
          <StudentLoansLandingMpower path="/student-loans-landing-mpower" />
          <StudentLoansLandingHDFC path="/student-loans-landing-hdfc" />
          <StudentLoansLandingWithoutCollateral path="/education-loan-without-collateral" />
          <StudentLoansLandingNoCosigner path="/education-loans-without-co-applicant" />
          <StudentLoansLandingIncred path="/incred-education-loan" />
          <StudentLoansLandingAuxilo path="/auxilo-education-loan" />
          <StudentLoansLandingForAbroadStudies path="/education-loan-for-abroad-studies" />
          <StudentLoansLandingVisaMadeEasy path="/visa-made-easy" />
          <StudentLoansLandingCollegeFinder path="/college-finder" />
          {/* <StudentLoansLandingUscPartnerCollateral path="/student-loans-landing-usc-partner-collateral" /> */}
          {/* <StudentLoansLandingCompare path="/student-loans-landing-compare" /> */}
          <Terms path='/terms' />
          <Privacy path='/privacy' />
          <Faqs path='/faqs' />
          <HowItWorks  path='/how-it-works' />
          <Contact  path='/contact-us' />
          <TestimonialsView path="/testimonials" />
          <Partners path="/partners" />
          <CalendlyWidget path='/schedule' />
          <CalendlyWidget path='/calendar' />
          <SiteMap path="/site-map" />
          {/* PORTAL */}
          <Dashboard path="/dashboard" />
          <ForgetPassword path="/forgot-password" />
          <MyDocuments path="/my-documents" />
          <SignUp path="/sign-up/:type" />
          <SignUp path="/sign-up" />
          <SignUp path="/three/part/path" />
          <SignIn path="/sign-in" />
          <SignIn path="/sign-in/:message" />
          <Options path="/options/:id" />
          <Edit path="/edit/:id" />
          <NewApplication path="/new/:type" />
        </Router>
        <Footer context={{landingPages:landingPages, portalPages: portalPages}}  />
        {/* <TawkMessengerReact
            propertyId="58fa297464f23d19a89ae838"
            widgetId="default"
            ref={tawkMessengerRef}
            onLoad={onTawkLoad}
        /> */}
      </div>
     {/* </ThemeContext.Provider> */}
     </HelmetProvider>
     </PostHogProvider>
     </>
  ); 
}

export default App;
